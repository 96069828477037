var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-main",
    [
      _c("page-title", { attrs: { "show-back-btn": "" } }),
      _c("div", { staticClass: "partition-area" }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.loading.detail,
                expression: "loading.detail"
              }
            ],
            staticClass: "partition-area"
          },
          [
            _c(
              "el-form",
              {
                ref: "appForm",
                attrs: {
                  model: _vm.appForm,
                  rules: _vm.rules,
                  "label-width": "180px",
                  "label-suffix": _vm.constants.labelSuffix
                }
              },
              [
                _c(
                  "ics-page-inner",
                  { attrs: { title: "基本信息" } },
                  [
                    _vm.$route.params.editMode === "add"
                      ? _c(
                          "el-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: { prop: "modelName", label: "模型名称" }
                              },
                              [
                                _c(
                                  "ics-item-inner",
                                  {
                                    attrs: {
                                      prop: _vm.appForm.modelName,
                                      format: _vm.utils.isEffectiveCommon
                                    }
                                  },
                                  [
                                    _c("el-input", {
                                      attrs: { placeholder: "请输入模型名称" },
                                      model: {
                                        value: _vm.appForm.modelName,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.appForm,
                                            "modelName",
                                            $$v
                                          )
                                        },
                                        expression: "appForm.modelName"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.$route.params.editMode !== "add"
                      ? _c(
                          "el-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "模型名称" } },
                              [
                                _c("p", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.utils.isEffectiveCommon(
                                        _vm.appForm.modelName
                                      )
                                    )
                                  )
                                ])
                              ]
                            )
                          ],
                          1
                        )
                      : _vm._e(),
                    _c("el-col")
                  ],
                  1
                ),
                _c(
                  "ics-page-inner",
                  { attrs: { title: "产品属性配置" } },
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 12 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { prop: "attribute.minCycle" } },
                          [
                            _c(
                              "el-checkbox",
                              {
                                attrs: { disabled: !_vm.viewEdit() },
                                on: { change: _vm.handleCheckAllChange },
                                model: {
                                  value: _vm.appForm.attribute.minCycle,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.appForm.attribute,
                                      "minCycle",
                                      $$v
                                    )
                                  },
                                  expression: "appForm.attribute.minCycle"
                                }
                              },
                              [_vm._v(" 免收费期限 ")]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 12 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { prop: "attribute.extension" } },
                          [
                            _c(
                              "el-checkbox",
                              {
                                attrs: { disabled: !_vm.viewEdit() },
                                on: { change: _vm.handleCheckAllChange },
                                model: {
                                  value: _vm.appForm.attribute.extension,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.appForm.attribute,
                                      "extension",
                                      $$v
                                    )
                                  },
                                  expression: "appForm.attribute.extension"
                                }
                              },
                              [_vm._v(" 宽限期 ")]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _vm.viewEdit()
                      ? _c("ics-button-inner", {
                          attrs: {
                            loading: _vm.loading.submit,
                            "submit-title": "提交",
                            "cancel-title": "取消"
                          },
                          on: { submit: _vm.submitForms }
                        })
                      : _vm._e()
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }