<template>
  <el-main>
    <page-title show-back-btn />
    <div class="partition-area">
      <div v-loading="loading.detail" class="partition-area">
        <el-form ref="appForm" :model="appForm" :rules="rules" label-width="180px" :label-suffix="constants.labelSuffix">
          <ics-page-inner title="基本信息">
            <el-col v-if="$route.params.editMode === 'add'" :span="12">
              <el-form-item prop="modelName" label="模型名称">
                <ics-item-inner :prop="appForm.modelName" :format="utils.isEffectiveCommon">
                  <el-input v-model="appForm.modelName" placeholder="请输入模型名称" />
                </ics-item-inner>
              </el-form-item>
            </el-col>
            <el-col v-if="$route.params.editMode !== 'add'" :span="12">
              <el-form-item label="模型名称">
                <p>{{ utils.isEffectiveCommon(appForm.modelName) }}</p>
              </el-form-item>
            </el-col>
            <el-col />
          </ics-page-inner>
          <ics-page-inner title="产品属性配置">
            <el-col :span="12">
              <el-form-item prop="attribute.minCycle">
                <el-checkbox v-model="appForm.attribute.minCycle" :disabled="!viewEdit()" @change="handleCheckAllChange">
                  免收费期限
                </el-checkbox>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item prop="attribute.extension">
                <el-checkbox v-model="appForm.attribute.extension" :disabled="!viewEdit()" @change="handleCheckAllChange">
                  宽限期
                </el-checkbox>
              </el-form-item>
            </el-col>
            <ics-button-inner v-if="viewEdit()" :loading="loading.submit" submit-title="提交" cancel-title="取消" @submit="submitForms" />
          </ics-page-inner>
        </el-form>
      </div>
    </div>
  </el-main>
</template>

<script>
import { routeEnterMixin } from '@/assets/js/mixins'
export default {
  components: {},
  mixins: [routeEnterMixin],
  data () {
    return {
      id: this.$route.query.id,
      appForm: {
        modelName: '',
        state: 1,
        attribute: {
          method: true,
          amountRange: true,
          loanCeiling: true,
          minCycle: true,
          dayRate: true,
          extension: true,
          byStages: true,
          overdue: true,
          penaltyInterest: true,
          financing: true,
          serviceCharge: true
        }
      },
      rules: {
        modelName: this.changeRules({ name: '模型名称', required: true }),
      }
    }
  },
  created () {
    if (this.id) {
      this.getDetail()
    }
  },
  methods: {
    getDetail () {
      this.loading.detail = true
      this.api.product.model.detailModel({ id: this.id }).then(result => {
        const data = result.data.data || {}
        let attribute = JSON.parse(data.attribute)
        if (attribute.method === '1') { this.appForm.attribute.method = true } else { this.appForm.attribute.method = false }
        if (attribute.amountRange === '1') { this.appForm.attribute.amountRange = true } else { this.appForm.attribute.amountRange = false }
        if (attribute.loanCeiling === '1') { this.appForm.attribute.loanCeiling = true } else { this.appForm.attribute.loanCeiling = false }
        if (attribute.minCycle === '1') { this.appForm.attribute.minCycle = true } else { this.appForm.attribute.minCycle = false }
        if (attribute.dayRate === '1') { this.appForm.attribute.dayRate = true } else { this.appForm.attribute.dayRate = false }
        if (attribute.penaltyInterest === '1') { this.appForm.attribute.penaltyInterest = true } else { this.appForm.attribute.penaltyInterest = false }
        if (attribute.extension === '1') { this.appForm.attribute.extension = true } else { this.appForm.attribute.extension = false }
        if (attribute.byStages === '1') { this.appForm.attribute.byStages = true } else { this.appForm.attribute.byStages = false }
        if (attribute.overdue === '1') { this.appForm.attribute.overdue = true } else { this.appForm.attribute.overdue = false }
        if (attribute.financing === '1') { this.appForm.attribute.financing = true } else { this.appForm.attribute.financing = false }
        if (attribute.serviceCharge === '1') { this.appForm.attribute.serviceCharge = true } else { this.appForm.attribute.serviceCharge = false }
        const { modelName, state } = data
        this.appForm = this._.assign(this.appForm, { modelName, state })
      }).finally(() => {
        this.loading.detail = false
      })
    },

    handleCheckAllChange (val) {},
    submitForms () {
      this.$refs.appForm.validate().then(() => {
        this.loading.submit = true
        let attribute = {
          method: '',
          amountRange: '',
          loanCeiling: '',
          dayRate: '',
          penaltyInterest: '',
          minCycle: '',
          extension: '',
          byStages: '',
          overdue: '',
          financing: '',
          serviceCharge: ''
        }
        if (this.appForm.attribute.method === true) { attribute.method = '1' } else { attribute.method = '0' }
        if (this.appForm.attribute.amountRange === true) { attribute.amountRange = '1' } else { attribute.amountRange = '0' }
        if (this.appForm.attribute.loanCeiling === true) { attribute.loanCeiling = '1' } else { attribute.loanCeiling = '0' }
        if (this.appForm.attribute.dayRate === true) { attribute.dayRate = '1' } else { attribute.dayRate = '0' }
        if (this.appForm.attribute.penaltyInterest === true) { attribute.penaltyInterest = '1' } else { attribute.penaltyInterest = '0' }
        if (this.appForm.attribute.minCycle === true) { attribute.minCycle = '1' } else { attribute.minCycle = '0' }
        if (this.appForm.attribute.extension === true) { attribute.extension = '1' } else { attribute.extension = '0' }
        if (this.appForm.attribute.byStages === true) { attribute.byStages = '1' } else { attribute.byStages = '0' }
        if (this.appForm.attribute.overdue === true) { attribute.overdue = '1' } else { attribute.overdue = '0' }
        if (this.appForm.attribute.financing === true) { attribute.financing = '1' } else { attribute.financing = '0' }
        if (this.appForm.attribute.serviceCharge === true) { attribute.serviceCharge = '1' } else { attribute.serviceCharge = '0' }
        const attributeJson = JSON.stringify(attribute)
        let data = {
          modelName: this.appForm.modelName,
          state: this.appForm.state,
          attribute: attributeJson
        }
        let api = this.api.product.model.addModel
        if (this.id) {
          data.id = this.id
          api = this.api.product.model.altModel
        }
        api(data).then(result => {
          if (result.data.success === true) {
            this.loading.submit = false
            this.$message.success('操作成功')
            this.$router.back()
          } else {
            this.$message.error(result.data.message)
          }
        }).catch(e => {
          this.loading.submit = false
        })
      })
    }
  }
}
</script>

<style scoped>

</style>
